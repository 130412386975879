import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class Formato
{
    formatoId?:number = 0;
    descripcion?:string ="";
    estado?:boolean = false;

    constructor(_formatoId?:number,_descripcion?:string,_estado?:boolean)
    {
        this.formatoId = _formatoId;
        this.descripcion  =_descripcion;
        this.estado = _estado;
    }
}

async function  GuardarFormato(Formato:Formato):Promise<ResponseGenerico>
{
    return await Save<Formato>("Formato/Guardar",Formato,mainAxios);
} 

async function  EditarFormato(Formato:Formato):Promise<ResponseGenerico>
{
    return await Editar<Formato>("Formato/Actualizar",Formato,mainAxios);
} 

async function  EditarEstadoFormato(Formato:Formato):Promise<ResponseGenerico>
{
    return await Editar<Formato>("Formato/CambiarEstado",Formato,mainAxios);
} 

async function ObtenerFormato()
{
    return await Obtener<Formato>("Formato/Obtener",mainAxios);
}


async function ObtenerSelectFormato()
{
    return await Obtener<Formato>("Formato/ObtenerSelect",mainAxios);
}

export 
{
    Formato,
    GuardarFormato,
    EditarFormato,
    EditarEstadoFormato,
    ObtenerFormato,
    ObtenerSelectFormato
}